const { useMemo } = require("react");
const { ADMIN_LEVEL_ACCESS } = require("resources/constants/constData");

export const filteredAdmin = (role) => {
  return (
    ADMIN_LEVEL_ACCESS.filter((i) => {
      return role === i;
    }).length > 0
  );
};

export const mapStudentData = (data, replacedData) => {
  const learners = data.map((item2) => {
    const item1 = replacedData.find((item) => item.email_id === item2.email_id);

    if (!item1) return item2;

    const mergedLearningPath = item2.learning_path.phase_list.map((phase2) => {
      const phase1 = item1.learning_path.phase_list.find(
        (p1) => p1.phase_name === phase2.phase_name
      );
      if (!phase1) return phase2;

      const mergedMacroCertifications = phase2.macro_certification_list.map(
        (macroCert2) => {
          const macroCert1 = phase1.macro_certification_list.find(
            (mc1) =>
              mc1.macro_certification_name ===
              macroCert2.macro_certification_name
          );

          if (!macroCert1) return macroCert2;

          const mergedCourses = macroCert2.course_list.map((course2) => {
            const course1 = macroCert1.course_list.find(
              (c1) => c1.course_name === course2.course_name
            );
            if (!course1) return course2;

            const mergedLessons = course2.lesson_list?.map((lesson2) => {
              const lesson1 = course1.lesson_list?.find(
                (l1) => l1.lesson_name === lesson2.lesson_name
              );

              return lesson1
                ? {
                    ...lesson1,
                    lesson_skill_points_actual:
                      lesson2.lesson_skill_points_actual,
                    lesson_skill_points_total:
                      lesson2.lesson_skill_points_total,
                  }
                : lesson2;
            });

            // Merge the course progress or any other data from course1 (replacedData) into course2 (data)
            return {
              ...course2,
              course_skill_points_actual:
                course1.course_skill_points_actual ??
                course2.course_skill_points_actual,
              course_skill_points_total:
                course1.course_skill_points_total ??
                course2.course_skill_points_total,
              course_progress: course1.course_progress, // Example field merge
              lesson_list: mergedLessons,
            };
          });

          return {
            ...macroCert2,
            course_list: mergedCourses,
          };
        }
      );

      return {
        ...phase2,
        macro_certification_list: mergedMacroCertifications,
      };
    });

    return {
      ...item2,
      learner_progress_bar: item1.learner_progress_bar,
      learning_path: {
        ...item2.learning_path,
        phase_list: mergedLearningPath,
      },
    };
  });

  return learners;
};

export const getPathAndScore = (course_name, arr, track) => {
  const finalResult = arr?.flatMap((item) =>
    item?.course
      ?.filter((items) => items.course_name === course_name)
      .flatMap((student) =>
        student?.student_data
          ?.filter((stg) => stg?.student_email === track.email_id)
          .flatMap((data) =>
            data?.assignment_submitted_detail?.flatMap(({ path, score }) => ({
              path,
              score,
            }))
          )
      )
  );

  const finalQuizResult = arr?.flatMap((item) =>
    item?.course
      ?.filter((items) => items.course_name === course_name)
      .flatMap((student) =>
        student?.student_data
          ?.filter((stg) => stg?.student_email === track.email_id)
          .flatMap((lesson) =>
            lesson?.lessons?.flatMap((room) =>
              room?.room?.flatMap((quiz) =>
                quiz?.quiz?.flatMap(({ quiz_name, quiz_score }) => ({
                  name: quiz_name,
                  score: quiz_score,
                }))
              )
            )
          )
      )
  );

  const finalKahootResult = arr?.flatMap((item) =>
    item?.course
      ?.filter((items) => items.course_name === course_name)
      .flatMap((student) =>
        student?.student_data
          ?.filter((stg) => stg?.student_email === track.email_id)
          .flatMap((lesson) =>
            lesson?.lessons?.flatMap((room) =>
              room?.room
                ?.filter((room_name) => room_name.kahoot_game_name != "")
                ?.flatMap(({ kahoot_game_name }) => ({
                  name: kahoot_game_name,
                  score: 0,
                }))
            )
          )
      )
  );
  const quizScore = finalQuizResult?.filter(Boolean);
  const quizSum = quizScore?.reduce(
    (accumulator, item) => accumulator + item.score,
    0
  );

  return {
    assignment: finalResult?.filter(Boolean),
    quiz: quizScore,
    skillCount: parseInt(quizSum / quizScore?.length),
    KahootResult: finalKahootResult?.filter(Boolean),
  };
};

export function getScoreAndPathForQuest(cohortsData, questName, type) {
  console.log(questName, "02 Linux Fundmentals");
  const data = cohortsData
    ?.flatMap(
      (cohort) =>
        cohort.course
          ?.filter((course) => course.course_name === questName)
          .flatMap((course) =>
            course.student_data?.flatMap((student) =>
              student.lessons?.flatMap((lesson) =>
                lesson.room?.flatMap((room) =>
                  type
                    ? room.quiz?.map((detail) => ({
                        score: detail.quiz_score,
                        name: detail.quiz_name,
                      }))
                    : room.assignment_submitted_detail?.map((detail) => ({
                        name: detail?.path,
                        score: detail?.score,
                      }))
                )
              )
            )
          ) || []
    )
    .filter(Boolean); // Remove undefined/null values
  console.log(data);
  return data;
}
